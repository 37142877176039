/*! PhotoSwipe main CSS by Dmytro Semenov | photoswipe.com */
.pswp {
  --pswp-bg: #000;
  --pswp-placeholder-bg: #222;
  --pswp-root-z-index: 100000;
  --pswp-preloader-color: #4f4f4f66;
  --pswp-preloader-color-secondary: #ffffffe6;
  --pswp-icon-color: #fff;
  --pswp-icon-color-secondary: #4f4f4f;
  --pswp-icon-stroke-color: #4f4f4f;
  --pswp-icon-stroke-width: 2px;
  --pswp-error-text-color: var(--pswp-icon-color);
  z-index: var(--pswp-root-z-index);
  touch-action: none;
  opacity: .003;
  contain: layout style size;
  -webkit-tap-highlight-color: #0000;
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.pswp:focus {
  outline: 0;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp--open {
  display: block;
}

.pswp, .pswp__bg {
  will-change: opacity;
  transform: translateZ(0);
}

.pswp__bg {
  opacity: .005;
  background: var(--pswp-bg);
}

.pswp, .pswp__scroll-wrap {
  overflow: hidden;
}

.pswp__scroll-wrap, .pswp__bg, .pswp__container, .pswp__item, .pswp__content, .pswp__img, .pswp__zoom-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__img, .pswp__zoom-wrap {
  width: auto;
  height: auto;
}

.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img, .pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active, .pswp__img {
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
  cursor: zoom-out;
}

.pswp__container, .pswp__img, .pswp__button, .pswp__counter {
  -webkit-user-select: none;
  user-select: none;
}

.pswp__item {
  z-index: 1;
  overflow: hidden;
}

.pswp__hidden {
  display: none !important;
}

.pswp__content {
  pointer-events: none;
}

.pswp__content > * {
  pointer-events: auto;
}

.pswp__error-msg-container {
  display: grid;
}

.pswp__error-msg {
  color: var(--pswp-error-text-color);
  margin: auto;
  font-size: 1em;
  line-height: 1;
}

.pswp .pswp__hide-on-close {
  opacity: .005;
  will-change: opacity;
  transition: opacity var(--pswp-transition-duration) cubic-bezier(.4, 0, .22, 1);
  z-index: 10;
  pointer-events: none;
}

.pswp--ui-visible .pswp__hide-on-close {
  opacity: 1;
  pointer-events: auto;
}

.pswp__button {
  cursor: pointer;
  box-shadow: none;
  opacity: .85;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  background: none;
  border: 0;
  width: 50px;
  height: 60px;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.pswp__button:hover, .pswp__button:active, .pswp__button:focus {
  box-shadow: none;
  opacity: 1;
  background: none;
  border: 0;
  padding: 0;
  transition: none;
}

.pswp__button:disabled {
  opacity: .3;
  cursor: auto;
}

.pswp__icn {
  fill: var(--pswp-icon-color);
  color: var(--pswp-icon-color-secondary);
  pointer-events: none;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 14px;
  left: 9px;
  overflow: hidden;
}

.pswp__icn-shadow {
  stroke: var(--pswp-icon-stroke-color);
  stroke-width: var(--pswp-icon-stroke-width);
  fill: none;
}

.pswp__icn:focus {
  outline: 0;
}

div.pswp__img--placeholder, .pswp__img--with-bg {
  background: var(--pswp-placeholder-bg);
}

.pswp__top-bar {
  z-index: 10;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 60px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none !important;
}

.pswp__top-bar > * {
  pointer-events: auto;
  will-change: opacity;
}

.pswp__button--close {
  margin-right: 6px;
}

.pswp__button--arrow {
  width: 75px;
  height: 100px;
  margin-top: -50px;
  position: absolute;
  top: 50%;
}

.pswp__button--arrow:disabled {
  cursor: default;
  display: none;
}

.pswp__button--arrow .pswp__icn {
  background: none;
  border-radius: 0;
  width: 60px;
  height: 60px;
  margin-top: -30px;
  top: 50%;
}

.pswp--one-slide .pswp__button--arrow {
  display: none;
}

.pswp--touch .pswp__button--arrow {
  visibility: hidden;
}

.pswp--has_mouse .pswp__button--arrow {
  visibility: visible;
}

.pswp__button--arrow--prev {
  left: 0;
  right: auto;
}

.pswp__button--arrow--next {
  right: 0;
}

.pswp__button--arrow--next .pswp__icn {
  left: auto;
  right: 14px;
  transform: scale(-1, 1);
}

.pswp__button--zoom {
  display: none;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

.pswp--zoomed-in .pswp__zoom-icn-bar-v {
  display: none;
}

.pswp__preloader {
  width: 50px;
  height: 60px;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.pswp__preloader .pswp__icn {
  opacity: 0;
  transition: opacity .2s linear;
  animation: .6s linear infinite pswp-clockwise;
}

.pswp__preloader--active .pswp__icn {
  opacity: .85;
}

@keyframes pswp-clockwise {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pswp__counter {
  color: var(--pswp-icon-color);
  text-shadow: 1px 1px 3px var(--pswp-icon-color-secondary);
  opacity: .85;
  margin-top: 15px;
  height: 30px;
  margin-inline-start: 20px;
  font-size: 14px;
  line-height: 30px;
}

.pswp--one-slide .pswp__counter {
  display: none;
}
/*# sourceMappingURL=index.0c48f29d.css.map */
